import React from "react";
// import * as FaIcons from 'react-icons/fa'
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [
	{
		title: "Home",
		path: "#home",
		icon: <AiIcons.AiFillHome />,
		iconClosed: <RiIcons.RiArrowDownSFill />,
		iconOpened: <RiIcons.RiArrowUpSFill />,
	},
	{
		title: "Calendar",
		path: "#calendar",
		icon: <AiIcons.AiFillCalendar />,
		iconClosed: <RiIcons.RiArrowDownSFill />,
		iconOpened: <RiIcons.RiArrowUpSFill />,
	},
	{
		title: "Standings",
		path: "#standings",
		icon: <IoIcons.IoIosPaper />,
		iconClosed: <RiIcons.RiArrowDownSFill />,
		iconOpened: <RiIcons.RiArrowUpSFill />,
		// subNav: [
		//   {
		//     title: "GP1",
		//     path: "#gp1Standings",
		//     icon: <IoIcons.IoIosPaper />,
		//   },
		//   {
		//     title: "GP2",
		//     path: "#gp2Standings",
		//     icon: <IoIcons.IoIosPaper />,
		//   },
		// ],
	},
	{
		title: "Past Streams",
		path: "#pastStreams",
		icon: <AiIcons.AiFillVideoCamera />,
	},
	{
		title: "Gallery",
		path: "#gallery",
		icon: <AiIcons.AiFillCamera />,
	},
	{
		title: "Hall of Fame",
		path: "#hallOfFame",
		icon: <AiIcons.AiFillCrown />,
	},
];

export const pageLinks = [
	{ id: 1, href: "#home", text: "home" },
	{ id: 2, href: "#calendar", text: "calendar" },
	{ id: 3, href: "#standings", text: "standings" },
	{ id: 4, href: "#pastStreams", text: "past streams" },
	{ id: 5, href: "#gallery", text: "gallery" },
	{ id: 6, href: "#hallOfFame", text: "hall of fame" },
];

export const socialLinks = [
	{
		id: 1,
		href: "https://www.facebook.com/SpeedForceRacingPS4/",
		icon: "fab fa-facebook",
	},
	{
		id: 2,
		href: "https://twitter.com/SFRps4",
		icon: "fab fa-twitter",
	},
	{
		id: 3,
		href: "https://discord.gg/8hB3MMfGy4",
		icon: "fab fa-discord",
	},
];

export const currentHofConfig = [
	{
		id: 15,
		season: "15",
		src: "/images/hof-s15.png",
	},
	{
		id: 14,
		season: "14",
		src: "/images/hof-s14.png",
	},
];

export const hofConfig = [
	{
		id: 13,
		season: "13",
		src: "/images/hof-s13.png",
	},
	{
		id: 12,
		season: "12",
		src: "/images/hof-s12.png",
	},
	{
		id: 11,
		season: "11",
		src: "/images/hof-s11.png",
	},
	{
		id: 10,
		season: "10",
		src: "/images/hof-s10.png",
	},
	{
		id: 9,
		season: "9",
		src: "/images/hof-s9.png",
	},
	{
		id: 8,
		season: "8",
		src: "/images/hof-s8.png",
	},
	{
		id: 7,
		season: "7",
		src: "/images/hof-s7.png",
	},
	{
		id: 6,
		season: "6",
		src: "/images/hof-s6.png",
	},
	{
		id: 5,
		season: "5",
		src: "/images/hof-s5.png",
	},
	{
		id: 4,
		season: "4",
		src: "/images/hof-s4.png",
	},
	{
		id: 3,
		season: "3",
		src: "/images/hof-s3.png",
	},
	{
		id: 2,
		season: "2",
		src: "/images/hof-s2.png",
	},
	{
		id: 1,
		season: "1",
		src: "/images/hof-s1.png",
	},
];

export const raceResultsDiv1 = [
	{
		id: 1,
		season: "18",
		src: "/images/div1-race-result-rnd-8.png",
	},
];

export const raceResultsDiv2 = [
	{
		id: 1,
		season: "18",
		src: "/images/div2-race-result-rnd-8.png",
	},
];

export const constructorsGraphicGp1 = [
	{
		id: 1,
		season: "18",
		src: "/images/div-1-constructors-rnd-8.png",
	},
];

export const constructorsGraphic = [
	{
		id: 1,
		season: "18",
		src: "/images/div-2-constructors-rnd-8.png",
	},
];

export const constructorsAllGraphic = [
	{
		id: 1,
		season: "18",
		src: "/images/div-all-constructors-rnd-8.png",
	},
];

export const gp1Slider = [
	{
		id: 1,
		imgPath: "/images/lights-out.jpg",
		desc: "And it's lights out and away we go at Bahrain for the opening race of Season 14!",
	},
	{
		id: 2,
		imgPath: "/images/kierannn.jpg",
		desc: "The McLaren of Kierannnn_88 in the lead of the Bahrain GP, with his teammate in tow - Bahrain Rnd 1",
	},
	{
		id: 3,
		imgPath: "/images/alpha-line.jpg",
		desc: "RogueSmithy dragging his car home as he runs out of fuel - Bahrain Rnd 1",
	},
];

export const streamConfig = [
	{
		id: 1,
		src: "https://www.youtube.com/embed/7lpXbItSs_M",
		title: "Div 1 - Rnd 22 - Vegas",
	},
	{
		id: 2,
		src: "https://www.youtube.com/embed/fsfhkbEJeZY",
		title: "Div 2 - Rnd 22 - Vegas",
	},
	{
		id: 3,
		src: "https://www.youtube.com/embed/hkZ6PdsZ4mY",
		title: "Div 3 - Rnd 22 - Vegas",
	},
];

export const mergedStandingsConfig = [
	{
		id: 1,
		position: "1",
		league: 1,
		name: "OWR_Harry_Kane",
		team: "Red Bull",
		teamBadge: "/images/team-rb.png",
		wins: "10",
		podiums: "13",
		fastest: "4",
		points: "404",
	},
	{
		id: 2,
		position: "2",
		league: 1,
		name: "DF_Laser",
		team: "McLaren",
		teamBadge: "/images/team-mcl.png",
		wins: "1",
		podiums: "8",
		fastest: "2",
		points: "362",
	},
	{
		id: 3,
		position: "3",
		league: 1,
		name: "CT_Noldus",
		team: "Mercedes",
		teamBadge: "/images/team-merc.png",
		wins: "",
		podiums: "2",
		fastest: "2",
		points: "234",
	},
	{
		id: 4,
		position: "4",
		league: 1,
		name: "OWR_BareRemi",
		team: "Red Bull",
		teamBadge: "/images/team-rb.png",
		wins: "1",
		podiums: "2",
		fastest: "1",
		points: "228",
	},
	{
		id: 5,
		position: "5",
		league: 1,
		name: "TNR_Langfjell",
		team: "Alpine",
		teamBadge: "/images/team-alp.png",
		wins: "",
		podiums: "",
		fastest: "",
		points: "212",
	},
	{
		id: 6,
		position: "6",
		league: 1,
		name: "OWR_LordDannyBoy",
		team: "Alpine",
		teamBadge: "/images/team-alp.png",
		wins: "",
		podiums: "",
		fastest: "1",
		points: "174",
	},
	{
		id: 7,
		position: "7",
		league: 1,
		name: "pocketvalleyguy",
		team: "McLaren",
		teamBadge: "/images/team-mcl.png",
		wins: "1",
		podiums: "1",
		fastest: "1",
		points: "140",
	},
	{
		id: 8,
		position: "8",
		league: 1,
		name: "OWR_DrauGen",
		team: "Aston Martin",
		teamBadge: "/images/team-am.png",
		wins: "",
		podiums: "",
		fastest: "1",
		points: "137",
	},

	{
		id: 9,
		position: "9",
		league: 1,
		name: "mdj2002mdj",
		team: "RB",
		teamBadge: "/images/team-visa.png",
		wins: "",
		podiums: "3",
		fastest: "",
		points: "128",
	},
	{
		id: 10,
		position: "10",
		league: 1,
		name: "Fireblaze95_",
		team: "Williams",
		teamBadge: "/images/team-will.png",
		wins: "1",
		podiums: "3",
		fastest: "",
		points: "104",
	},
	{
		id: 11,
		position: "11",
		league: 1,
		name: "bluerocket05",
		team: "Ferrari",
		teamBadge: "/images/team-fer.png",
		wins: "",
		podiums: "2",
		fastest: "",
		points: "89",
	},
	{
		id: 12,
		position: "12",
		league: 1,
		name: "SHZ Jensen",
		team: "Haas",
		teamBadge: "/images/team-haas.png",
		wins: "",
		podiums: "2",
		fastest: "1",
		points: "81",
	},
	{
		id: 13,
		position: "13",
		league: 1,
		name: "TTVKaname",
		team: "Ferrari",
		teamBadge: "/images/team-fer.png",
		wins: "1",
		podiums: "2",
		fastest: "",
		points: "63",
	},
	{
		id: 14,
		position: "14",
		league: 1,
		name: "noadietrich10",
		team: "Williams",
		teamBadge: "/images/team-will.png",
		wins: "",
		podiums: "",
		fastest: "",
		points: "59",
	},
	{
		id: 15,
		position: "15",
		league: 1,
		name: "rising_star1115",
		team: "Haas",
		teamBadge: "/images/team-haas.png",
		wins: "",
		podiums: "1",
		fastest: "",
		points: "55",
	},
	{
		id: 16,
		position: "16",
		league: 1,
		name: "AMS-Nico05",
		team: "Aston Martin",
		teamBadge: "/images/team-am.png",
		wins: "1",
		podiums: "2",
		fastest: "1",
		points: "48",
	},
	{
		id: 17,
		position: "17",
		league: 1,
		name: "Jesse-de-cranker",
		team: "RB",
		teamBadge: "/images/team-visa.png",
		wins: "",
		podiums: "",
		fastest: "",
		points: "24",
	},
	{
		id: 18,
		position: "18",
		league: 1,
		name: "RyZe",
		team: "Mercedes",
		teamBadge: "/images/team-merc.png",
		wins: "",
		podiums: "",
		fastest: "",
		points: "21",
	},
	{
		id: 19,
		position: "19",
		league: 1,
		name: "Bobbylons",
		team: "Kick Sauber",
		teamBadge: "/images/team-kick.png",
		wins: "",
		podiums: "0",
		fastest: "",
		points: "20",
	},
	{
		id: 20,
		position: "20",
		league: 1,
		name: "cricab06_",
		team: "Kick Sauber",
		teamBadge: "/images/team-kick.png",
		wins: "",
		podiums: "",
		fastest: "",
		points: "7",
	},
	{
		id: 21,
		position: "1",
		league: 2,
		name: "Mansell-_-19",
		team: "Aston Martin",
		teamBadge: "/images/team-am.png",
		wins: "4",
		podiums: "8",
		fastest: "1",
		points: "324",
	},
	{
		id: 22,
		position: "2",
		league: 2,
		name: "BUR_MEISTER",
		team: "Williams",
		teamBadge: "/images/team-will.png",
		wins: "2",
		podiums: "10",
		fastest: "2",
		points: "319",
	},
	{
		id: 23,
		position: "3",
		league: 2,
		name: "Aydams1",
		team: "McLaren",
		teamBadge: "/images/team-mcl.png",
		wins: "2",
		podiums: "8",
		fastest: "",
		points: "300",
	},
	{
		id: 24,
		position: "4",
		league: 2,
		name: "OWR_Atlascopo",
		team: "Red Bull",
		teamBadge: "/images/team-rb.png",
		wins: "",
		podiums: "3",
		fastest: "3",
		points: "279",
	},
	{
		id: 25,
		position: "5",
		league: 2,
		name: "Klusser40",
		team: "Red Bull",
		teamBadge: "/images/team-rb.png",
		wins: "",
		podiums: "1",
		fastest: "",
		points: "276",
	},
	{
		id: 26,
		position: "6",
		league: 2,
		name: "SFRCalvin_Racing",
		team: "Kick Sauber",
		teamBadge: "/images/team-kick.png",
		wins: "1",
		podiums: "4",
		fastest: "",
		points: "270",
	},
	{
		id: 27,
		position: "7",
		league: 2,
		name: "SFR-EnergyDan",
		team: "Alpine",
		teamBadge: "/images/team-alp.png",
		wins: "",
		podiums: "",
		fastest: "",
		points: "265",
	},
	{
		id: 28,
		position: "8",
		league: 2,
		name: "SFR_Keefs",
		team: "McLaren",
		teamBadge: "/images/team-mcl.png",
		wins: "2",
		podiums: "3",
		fastest: "1",
		points: "257",
	},
	{
		id: 29,
		position: "9",
		league: 2,
		name: "SFR_SonicHawk",
		team: "RB",
		teamBadge: "/images/team-visa.png",
		wins: "1",
		podiums: "1",
		fastest: "",
		points: "240",
	},
	{
		id: 30,
		position: "10",
		league: 2,
		name: "Smoke_FX_44",
		team: "Alpine",
		teamBadge: "/images/team-alp.png",
		wins: "4",
		podiums: "7",
		fastest: "",
		points: "234.5",
	},
	{
		id: 31,
		position: "11",
		league: 2,
		name: "Cassidy1690",
		team: "Kick Sauber",
		teamBadge: "/images/team-kick.png",
		wins: "",
		podiums: "3",
		fastest: "2",
		points: "210",
	},
	{
		id: 32,
		position: "12",
		league: 2,
		name: "Mindful_Wasp",
		team: "Aston Martin",
		teamBadge: "/images/team-am.png",
		wins: "",
		podiums: "1",
		fastest: "6",
		points: "178",
	},
	{
		id: 33,
		position: "13",
		league: 2,
		name: "OWR_Xenon",
		team: "Ferrari",
		teamBadge: "/images/team-fer.png",
		wins: "",
		podiums: "1",
		fastest: "",
		points: "138",
	},
	{
		id: 34,
		position: "14",
		league: 2,
		name: "OWR_Emils1991",
		team: "Ferrari",
		teamBadge: "/images/team-fer.png",
		wins: "",
		podiums: "1",
		fastest: "",
		points: "134",
	},
	{
		id: 35,
		position: "15",
		league: 2,
		name: "SFR-OliverF1",
		team: "Williams",
		teamBadge: "/images/team-will.png",
		wins: "",
		podiums: "2",
		fastest: "",
		points: "128",
	},
	{
		id: 36,
		position: "16",
		league: 2,
		name: "LordBradTheTopG",
		team: "Mercedes",
		teamBadge: "/images/team-merc.png",
		wins: "1",
		podiums: "4",
		fastest: "",
		points: "120",
	},
	{
		id: 37,
		position: "17",
		league: 2,
		name: "OWR_Ronny",
		team: "Mercedes",
		teamBadge: "/images/team-merc.png",
		wins: "",
		podiums: "",
		fastest: "",
		points: "89",
	},
	{
		id: 38,
		position: "18",
		league: 2,
		name: "ElectroDan",
		team: "Haas",
		teamBadge: "/images/team-haas.png",
		wins: "1",
		podiums: "2",
		fastest: "",
		points: "85",
	},
	{
		id: 39,
		position: "19",
		league: 2,
		name: "Yarham_",
		team: "Haas",
		teamBadge: "/images/team-haas.png",
		wins: "1",
		podiums: "1",
		fastest: "1",
		points: "56",
	},
	{
		id: 40,
		position: "20",
		league: 2,
		name: "DinoBinoBambino",
		team: "RB",
		teamBadge: "/images/team-visa.png",
		wins: "",
		podiums: "",
		fastest: "",
		points: "38",
	},
	{
		id: 41,
		position: "1",
		league: 3,
		name: "OWR_Prberli (C)",
		team: "Haas",
		teamBadge: "/images/team-haas.png",
		wins: "6",
		podiums: "14",
		fastest: "2",
		points: "427",
	},
	{
		id: 42,
		position: "2",
		league: 3,
		name: "MrWigglesFF",
		team: "Alpine",
		teamBadge: "/images/team-alp.png",
		wins: "2",
		podiums: "9",
		fastest: "1",
		points: "346",
	},
	{
		id: 43,
		position: "3",
		league: 3,
		name: "SFR_Doran",
		team: "Red Bull",
		teamBadge: "/images/team-rb.png",
		wins: "1",
		podiums: "6",
		fastest: "3",
		points: "337",
	},
	{
		id: 44,
		position: "4",
		league: 3,
		name: "SFR_TheVicar",
		team: "Alpine",
		teamBadge: "/images/team-alp.png",
		wins: "3",
		podiums: "9",
		fastest: "1",
		points: "332",
	},
	{
		id: 45,
		position: "5",
		league: 3,
		name: "OWR_Mihtrandir",
		team: "Haas",
		teamBadge: "/images/team-haas.png",
		wins: "2",
		podiums: "5",
		fastest: "",
		points: "311",
	},
	{
		id: 46,
		position: "6",
		league: 3,
		name: "OWR_Staink",
		team: "Mercedes",
		teamBadge: "/images/team-merc.png",
		wins: "1",
		podiums: "3",
		fastest: "",
		points: "282",
	},
	{
		id: 47,
		position: "7",
		league: 3,
		name: "SFR_MADG",
		team: "Williams",
		teamBadge: "/images/team-will.png",
		wins: "2",
		podiums: "4",
		fastest: "",
		points: "275",
	},
	{
		id: 48,
		position: "8",
		league: 3,
		name: "BillyG1977",
		team: "Red Bull",
		teamBadge: "/images/team-rb.png",
		wins: "",
		podiums: "3",
		fastest: "2",
		points: "274",
	},
	{
		id: 49,
		position: "9",
		league: 3,
		name: "OWR_Halvor",
		team: "Kick Sauber",
		teamBadge: "/images/team-kick.png",
		wins: "1",
		podiums: "1",
		fastest: "1",
		points: "266",
	},
	{
		id: 50,
		position: "10",
		league: 3,
		name: "Kris-Big-Pro",
		team: "Mercedes",
		teamBadge: "/images/team-merc.png",
		wins: "3",
		podiums: "5",
		fastest: "2",
		points: "240",
	},
	{
		id: 51,
		position: "11",
		league: 3,
		name: "xEllieCx",
		team: "Ferrari",
		teamBadge: "/images/team-fer.png",
		wins: "",
		podiums: "3",
		fastest: "",
		points: "212",
	},
	{
		id: 52,
		position: "12",
		league: 3,
		name: "Gogs0407",
		team: "Aston Martin",
		teamBadge: "/images/team-am.png",
		wins: "1",
		podiums: "4",
		fastest: "3",
		points: "209",
	},
	{
		id: 53,
		position: "13",
		league: 3,
		name: "GeVi Orieuglas",
		team: "Williams",
		teamBadge: "/images/team-will.png",
		wins: "",
		podiums: "",
		fastest: "",
		points: "185",
	},
	{
		id: 54,
		position: "14",
		league: 3,
		name: "OWR_Silverfox",
		team: "RB",
		teamBadge: "/images/team-visa.png",
		wins: "",
		podiums: "",
		fastest: "",
		points: "146",
	},
	{
		id: 55,
		position: "15",
		league: 3,
		name: "OWR_Preb1",
		team: "Kick Sauber",
		teamBadge: "/images/team-kick.png",
		wins: "",
		podiums: "",
		fastest: "",
		points: "144",
	},
	{
		id: 56,
		position: "16",
		league: 3,
		name: "JonMowbs",
		team: "McLaren",
		teamBadge: "/images/team-mcl.png",
		wins: "",
		podiums: "",
		fastest: "",
		points: "134",
	},
	{
		id: 57,
		position: "17",
		league: 3,
		name: "HIM_Sofanico",
		team: "Aston Martin",
		teamBadge: "/images/team-am.png",
		wins: "",
		podiums: "",
		fastest: "",
		points: "89",
	},
	{
		id: 58,
		position: "18",
		league: 3,
		name: "SFR-Simmo007",
		team: "Ferrari",
		teamBadge: "/images/team-fer.png",
		wins: "",
		podiums: "",
		fastest: "",
		points: "83",
	},
	{
		id: 59,
		position: "19",
		league: 3,
		name: "Dom9130",
		team: "RB",
		teamBadge: "/images/team-visa.png",
		wins: "",
		podiums: "",
		fastest: "",
		points: "23",
	},
	{
		id: 60,
		position: "20",
		league: 3,
		name: "redoubts",
		team: "McLaren",
		teamBadge: "/images/team-mcl.png",
		wins: "",
		podiums: "",
		fastest: "",
		points: "21",
	},
];

export const mergedConstructorsConfig = [
	{
		id: 1,
		position: "1",
		league: 1,
		team: "Red Bull",
		teamBadge: "/images/team-rb.png",
		wins: "11",
		points: "665",
	},
	{
		id: 2,
		position: "2",
		league: 1,
		team: "McLaren",
		teamBadge: "/images/team-mcl.png",
		wins: "2",
		points: "587",
	},
	{
		id: 3,
		position: "3",
		league: 1,
		team: "Williams",
		teamBadge: "/images/team-will.png",
		wins: "3",
		points: "461",
	},
	{
		id: 4,
		position: "4",
		league: 1,
		team: "Aston Martin",
		teamBadge: "/images/team-am.png",
		wins: "2",
		points: "426",
	},
	{
		id: 5,
		position: "5",
		league: 1,
		team: "Alpine",
		teamBadge: "/images/team-alp.png",
		wins: "",
		points: "407",
	},
	{
		id: 6,
		position: "6",
		league: 1,
		team: "RB",
		teamBadge: "/images/team-visa.png",
		wins: "1",
		points: "365",
	},
	{
		id: 7,
		position: "7",
		league: 1,
		team: "Ferrari",
		teamBadge: "/images/team-fer.png",
		wins: "1",
		points: "359",
	},
	{
		id: 8,
		position: "8",
		league: 1,
		team: "Mercedes",
		teamBadge: "/images/team-merc.png",
		wins: "1",
		points: "352",
	},
	{
		id: 9,
		position: "9",
		league: 1,
		team: "Kick Sauber",
		teamBadge: "/images/team-kick.png",
		wins: "",
		points: "314",
	},
	{
		id: 10,
		position: "10",
		league: 1,
		team: "Haas",
		teamBadge: "/images/team-haas.png",
		wins: "",
		points: "274",
	},
	{
		id: 11,
		position: "1",
		league: 2,
		team: "Alpine",
		teamBadge: "/images/team-alp.png",
		wins: "4",
		points: "596",
	},
	{
		id: 12,
		position: "2",
		league: 2,
		team: "Red Bull",
		teamBadge: "/images/team-rb.png",
		wins: "",
		points: "567",
	},
	{
		id: 13,
		position: "3",
		league: 2,
		team: "McLaren",
		teamBadge: "/images/team-mcl.png",
		wins: "4",
		points: "564",
	},
	{
		id: 14,
		position: "4",
		league: 2,
		team: "Aston Martin",
		teamBadge: "/images/team-am.png",
		wins: "4",
		points: "502",
	},
	{
		id: 15,
		position: "5",
		league: 2,
		team: "Kick Sauber",
		teamBadge: "/images/team-kick.png",
		wins: "1",
		points: "497",
	},
	{
		id: 16,
		position: "6",
		league: 2,
		team: "Williams",
		teamBadge: "/images/team-will.png",
		wins: "4",
		points: "479",
	},
	{
		id: 17,
		position: "7",
		league: 2,
		team: "RB",
		teamBadge: "/images/team-visa.png",
		wins: "2",
		points: "397",
	},
	{
		id: 18,
		position: "8",
		league: 2,
		team: "Ferrari",
		teamBadge: "/images/team-fer.png",
		wins: "",
		points: "323",
	},
	{
		id: 19,
		position: "9",
		league: 2,
		team: "Mercedes",
		teamBadge: "/images/team-merc.png",
		wins: "1",
		points: "269",
	},
	{
		id: 20,
		position: "10",
		league: 2,
		team: "Haas",
		teamBadge: "/images/team-haas.png",
		wins: "2",
		points: "236",
	},
	{
		id: 21,
		position: "1",
		league: 3,
		team: "Haas",
		teamBadge: "/images/team-haas.png",
		wins: "8",
		points: "756",
	},
	{
		id: 22,
		position: "2",
		league: 3,
		team: "Alpine",
		teamBadge: "/images/team-alp.png",
		wins: "5",
		points: "693",
	},
	{
		id: 23,
		position: "3",
		league: 3,
		team: "Red Bull",
		teamBadge: "/images/team-rb.png",
		wins: "1",
		points: "611",
	},
	{
		id: 24,
		position: "4",
		league: 3,
		team: "Mercedes",
		teamBadge: "/images/team-merc.png",
		wins: "4",
		points: "532",
	},
	{
		id: 25,
		position: "5",
		league: 3,
		team: "Williams",
		teamBadge: "/images/team-will.png",
		wins: "2",
		points: "408",
	},
	{
		id: 26,
		position: "6",
		league: 3,
		team: "Kick Sauber",
		teamBadge: "/images/team-kick.png",
		wins: "1",
		points: "395",
	},
	{
		id: 27,
		position: "7",
		league: 3,
		team: "Ferrari",
		teamBadge: "/images/team-fer.png",
		wins: "",
		points: "338",
	},
	{
		id: 28,
		position: "8",
		league: 3,
		team: "Aston Martin",
		teamBadge: "/images/team-am.png",
		wins: "1",
		points: "333",
	},
	{
		id: 29,
		position: "9",
		league: 3,
		team: "RB",
		teamBadge: "/images/team-visa.png",
		wins: "",
		points: "275",
	},
	{
		id: 30,
		position: "10",
		league: 3,
		team: "McLaren",
		teamBadge: "/images/team-mcl.png",
		wins: "",
		points: "225",
	},
];

export const constructorsAllConfig = [
	{
		id: 1,
		position: "1",
		team: "Red Bull",
		teamBadge: "/images/team-rb.png",
		div1: "665",
		div2: "567",
		div3: "611",
	},
	{
		id: 2,
		position: "2",
		team: "Alpine",
		teamBadge: "/images/team-alp.png",
		div1: "407",
		div2: "596",
		div3: "693",
	},
	{
		id: 3,
		position: "3",
		team: "McLaren",
		teamBadge: "/images/team-mcl.png",
		div1: "587",
		div2: "564",
		div3: "225",
	},
	{
		id: 4,
		position: "4",
		team: "Williams",
		teamBadge: "/images/team-will.png",
		div1: "461",
		div2: "479",
		div3: "408",
	},
	{
		id: 5,
		position: "5",
		team: "Haas",
		teamBadge: "/images/team-haas.png",
		div1: "274",
		div2: "236",
		div3: "756",
	},
	{
		id: 6,
		position: "6",
		team: "Aston Martin",
		teamBadge: "/images/team-am.png",
		div1: "426",
		div2: "502",
		div3: "333",
	},
	{
		id: 7,
		position: "7",
		team: "Kick Sauber",
		teamBadge: "/images/team-kick.png",
		div1: "314",
		div2: "497",
		div3: "395",
	},
	{
		id: 8,
		position: "8",
		team: "Mercedes",
		teamBadge: "/images/team-merc.png",
		div1: "352",
		div2: "269",
		div3: "532",
	},
	{
		id: 9,
		position: "9",
		team: "RB",
		teamBadge: "/images/team-visa.png",
		div1: "365",
		div2: "397",
		div3: "275",
	},
	{
		id: 10,
		position: "10",
		team: "Ferrari",
		teamBadge: "/images/team-fer.png",
		div1: "359",
		div2: "323",
		div3: "338",
	},
];
